import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../../Toggle-Switch"
import Select from "react-select"

let selectedUserId = []

export default function FetchModal(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [fetch, setFetch] = useState("")
  const [newsletter, setNewsletter] = useState(
    props.fetch && props.fetch.latest_leads_only === 1 ? true : false
  )
  const [allSource, setAllSource] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [selectedSource, setSelectedSource] = useState(
    props.fetch && props.fetch.platform
  )

  //   const onNewsletterChange = checked => {
  //     if (checked) {
  //       setNewsletter(1)
  //     } else {
  //       setNewsletter(0)
  //     }
  //   }

  const getAllUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setAllUsers(res.data.data.data)
      })
      .catch(err => {})
  }

  const getAllSource = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/fetch-from-api-source?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setAllSource(res.data.data.data)
      })
  }

  const handleSource = value => {
    setSelectedSource(value)
  }

  const submitFetch = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.fetch !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/fetch-from-api/${props.fetch.id}`,
          {
            api_key: value.api_key,
            platform: value.platform,
            lead_source_id: value.lead_source_id,
            latest_leads_only: 1,
            assignees: selectedUserId,
            username: value.username,
            password: value.password,
            profile_id: value.profile_id,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setIsOpen(false)
            props.setmodal_standard(false)
            props.setFetch(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/fetch-from-api`,
          {
            api_key: value.api_key,
            platform: value.platform,
            lead_source_id: value.lead_source_id,
            latest_leads_only: 1,
            assignees: selectedUserId,
            username: value.username,
            password: value.password,
            profile_id: value.profile_id,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllFetch()
            props.setmodal_standard(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  const options = []

  if (allUsers !== undefined) {
    allUsers.map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }
  const getSelectedId = e => {
    selectedUserId = []
    e?.map(item => selectedUserId.push(item.value))
  }

  const dValues = []

  useEffect(() => {
    getAllSource()
    getAllUsers()
    if (props.fetch !== undefined) {
      selectedUserId = []
      props.fetch.assigneename?.map(item => {
        dValues.push({
          value: item.id,
          label: item.name,
        })
        selectedUserId.push(item.id)
      })
    }
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.modal_standard} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitFetch(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.fetch !== undefined
                ? "Edit Fetch Status"
                : "Fetch From API"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_standard(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="mb-3"> */}
            {/* 	<AvField */}
            {/* 		label="Host" */}
            {/* 		type="text" */}
            {/* 		name="host" */}
            {/* 		defaultValue={props.fetch && props.fetch.host} */}
            {/* 	/> */}
            {/* </div> */}
            {/* <div className="mb-3"> */}
            {/* 	<AvField */}
            {/* 		label="Port" */}
            {/* 		type="text" */}
            {/* 		name="port" */}
            {/* 		defaultValue={props.fetch && props.fetch.port} */}
            {/* 	/> */}
            {/* </div> */}

            <div className="form-group mb-3">
              <AvField
                className="select-arrow"
                label="Select Lead Source"
                type="select"
                name="platform"
                defaultValue={props.fetch && props.fetch.platform}
                onChange={e => handleSource(e.target.value)}
              >
                <option value="" hidden>
                  Select Source Name
                </option>
                {/* <option value="pcd_pharma_express">PCD Pharma Express</option> */}
                <option value="india_mart">IndiaMart</option>
                <option value="tradeindia">TradeIndia</option>
                {/* <option value="trade_maantra">TradeMaantra</option> */}
                <option value="pharma_vends">Pharma Vends</option>
                {/* <option value="pharma_lair">Pharma Flair</option> */}
                <option value="pharma_hopers">Pharma Hopers</option>
                {/* <option value="pharma_biz_connect">Pharma Biz Connect</option> */}
                {/* <option value="dawa_charcha">Dawa Charcha</option> */}
                {/* <option value="pharma_franchise_mart">
                           Pharma Franchise Mart
                        </option> */}
                {/* <option value="nexttech_mart">Nexttech Mart</option> */}
                {/* <option value="pharma_franchisee_india">
                           Pharma Franchisee India
                        </option> */}
                {/* <option value="pharma_buffet">Pharma Buffet</option> */}
              </AvField>
            </div>
            {selectedSource !== "pharma_hopers" ? (
              <div className="mb-3">
                <AvField
                  label="API Key"
                  type="text"
                  name="api_key"
                  defaultValue={props.fetch && props.fetch.api_key}
                />
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <AvField
                    label="Username"
                    type="text"
                    name="username"
                    defaultValue={props.fetch && props.fetch.username}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label="Password"
                    type="password"
                    name="password"
                    defaultValue={props.fetch && props.fetch.password}
                  />
                </div>
              </>
            )}
            {selectedSource === "tradeindia" ? (
              <>
                <div className="mb-3">
                  <AvField
                    label="User ID"
                    type="text"
                    name="username"
                    defaultValue={props.fetch && props.fetch.username}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label="Profile ID"
                    type="text"
                    name="profile_id"
                    defaultValue={props.fetch && props.fetch.profile_id}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <div className="form-group mb-3">
              <AvField
                className="select-arrow"
                type="select"
                name="lead_source_id"
                label="Assign Source Name"
                defaultValue={props.fetch && props.fetch.lead_source_id}
              >
                <option value="" hidden>
                  Select Source Name
                </option>
                {allSource &&
                  allSource.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  ))}
              </AvField>
            </div>
            <div className="form-group mb-3">
              <label>Select User To Assign</label>
              <Select
                isMulti
                isClearable="true"
                name="assignees"
                options={options}
                defaultValue={dValues}
                onChange={e => getSelectedId(e)}
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="newsletter" className="me-3">
                Latest Leads Only
              </label>
              <ToggleSwitch
                id="newsletter"
                checked={newsletter}
                onChange={onNewsletterChange}
                name="latest_leads_only"
              />
            </div> */}
            <div className="modal-footer">
              <div className="text-center">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_standard(false)}
                  >
                    {props.fetch !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
