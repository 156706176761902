import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import SourceList from "./SourceList"
import SourceModal from "./SourceModal"
import InputFilter from "../../Filter/SearchFilter"
import DateFilter from "../../Filter/DateFilter"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const LeadSource = () => {
  const [modal_lead_source, setmodal_lead_source] = useState(false)
  const [allSource, setAllSource] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [name_filter, setname_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [blur, setBlur] = useState(false)
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [fromDate, setFromDate] = useState("")
  const [toDate, setTodate] = useState("")

  const getAllSource = () => {
    setLoader(<Loader />)
    setAllSource([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/source?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setAllSource(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
    getAllSources()
  }

  const getAllSources = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead/source?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("AllSource", JSON.stringify(res.data.data.data))
        } else {
        }
      })
  }

  const paginate = e => {
    setAllSource([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)

    const params = new URLSearchParams()

    const leadName = localStorage.getItem("leadName")
    if (leadName) {
      params.append("name", leadName)
    }

    const filterDate = getFilterDate()
    if (filterDate?.from) {
      params.append("fromdate", filterDate.from)
    }
    if (filterDate?.to) {
      params.append("todate", filterDate.to)
    }

    const apiUrl = `${e}${params.toString() ? `&${params.toString()}` : ""}`

    axios
      .get(apiUrl, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token || ""}`,
        },
      })
      .then(res => {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllSource(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
      .catch(error => {
        setError("An error occurred")
        setLoader("")
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")

    // Update local storage or filter date based on type
    if (type === "name") {
      localStorage.setItem("leadName", e)
    }
    if (type === "date") {
      setFilterDate(e)
    }

    setSearchedCount("")
    setAllSource([])

    // Dynamically build query parameters
    const params = new URLSearchParams()

    const leadName = localStorage.getItem("leadName")
    if (leadName) {
      params.append("name", leadName)
    }

    const filterDate = getFilterDate()
    if (filterDate?.from) {
      params.append("fromdate", filterDate.from)
    }
    if (filterDate?.to) {
      params.append("todate", filterDate.to)
    }

    const perPage = localStorage.getItem("PerPage")
    if (perPage) {
      params.append("per_page", perPage)
    }

    const apiUrl = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/lead/source?${params.toString()}`

    axios
      .get(apiUrl, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token || ""}`,
        },
      })
      .then(res => {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllSource(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setname_filter(false)
          setdate_filter(false)
          setFromDate(filterDate?.from || "")
          setTodate(filterDate?.to || "")
        } else {
          setError(res.data.message)
        }
      })
      .catch(() => {
        setError("An error occurred")
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const exportLead = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/leadsource-export`,
        {
          ids: leadsSelectedIds,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-leadsource-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const deleteLeads = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-source-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllSource()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    getAllSource()
  }, [])

  return (
    <>
      <SourceModal
        modal_lead_source={modal_lead_source}
        setmodal_lead_source={setmodal_lead_source}
        getAllSource={getAllSource}
      />
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Lead Sources</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {getPermission().lead_source.lead_source.delete.is_checked ===
              "yes" ? (
                blur ? (
                  <div className="btn-group me-2">
                    <button type="button" className="btn btn-primary">
                      Action
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <span
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setconfirm_alert(true)
                          }}
                        >
                          Batch delete
                        </span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="btn-group me-2">
                    <button type="button" className="btn btn-primary" disabled>
                      Action
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      disabled
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <span
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setconfirm_alert(true)
                          }}
                        >
                          Batch delete
                        </span>
                      </li>
                    </ul>
                  </div>
                )
              ) : (
                ""
              )}
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    exportLead()
                  }}
                >
                  Export
                </button>
              ) : (
                ""
              )}
              {getPermission().lead_source.lead_source.create.is_checked ===
              "yes" ? (
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => {
                    setmodal_lead_source(true)
                  }}
                >
                  Add New
                </button>
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>ID</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Source Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Total</th>
                  <th>Matured</th>
                  <th>Matured Amount</th>
                  <th>Total Business</th>
                  <th>New</th>
                  <th>Followup</th>
                  <th>Dead</th>
                  <th>Dead Request</th>
                  <th>Repeated</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Created at
                      {date_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate() && getFilterDate().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <DateFilter
                        date_filter={date_filter}
                        setdate_filter={setdate_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {allSource &&
                  allSource.map((item, obj) => (
                    <SourceList
                      key={obj}
                      data={item}
                      i={obj}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      counter={counter}
                      getAllSource={getAllSource}
                      getAllSources={getAllSources}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllSource}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default LeadSource
